body {
    color: #041c2c;
    font-family: 'Roboto Slab', serif;
}


img {
    display: block;
    max-inline-size: 90%;
    /* max-width: 90%; */
    max-height: 80vh;
    min-height: 70vh;
    /* min-width: 50%; */
    margin-left: auto;
    margin-right: auto;
}

.modaloverlay {
    width: 100vh;
    height: 100vh;
    margin: auto;
}

.modal {
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 15px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


.themeboxcontainer {
    width: 100%; 
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    
}

.themeboxes {
    display: flex;
    width: 90%;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #6bb8ff;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 5px #041c2c;
}

.themeboxes:hover {
    cursor: pointer;
}

.themeboxes p {
    font-size: 2em;
    padding: 10px;
}

.toppdivs {
    width: 100%;
    /* height: 800px; */
    background-color: white;
}

.toppdivs input {
    position: absolute;
    top: 0;
    left: 50%;
    border-color: transparent;
    caret-color: transparent;
    outline: none;
}

.slideshow-container {
    width: 100%;
    position: relative;
    margin: auto;
}

.prevbutton, .nextbutton {
    cursor: pointer;
    position: absolute;
    top: 300px;
    margin-top: -22px;
    width: auto;
    padding: 16px;
    color: grey;
    font-weight: bold;
    font-size: 2em;
    transition: 0.6s ease;
    border: none;
    user-select: none;
    background-color: transparent;
}

.nextbutton {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.prevbutton {
    left: 0;
}

.prevbutton:hover, .nextbutton:hover {
    color: black;
}

.myslides {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}

.result-topdiv {
    width: 100%;
    min-height: 400px;
    margin: auto;
    background-color: #6bb8ff;
    border-radius: 10px;
    box-shadow: 5px 5px 5px #041c2c;
}

.result-table {
    width: 95%;
    margin: auto;
    font-size: 1em;
    border-collapse: collapse;
}

.result-table th, td, h1 {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.italic {
    font-style: italic;
}